import React from "react";
import { RichText } from "./../../styles/richText";
import { Wrapper } from "./style";

const RichTextComponent = ({ textInHtml }) => {
	return (
		<Wrapper>
			<RichText>
				<div
					className="rich-text-wrapper"
					dangerouslySetInnerHTML={{ __html: textInHtml }}
				></div>
			</RichText>
		</Wrapper>
	);
};

export default RichTextComponent;
